<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Error Logs</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/employees-payroll"> Employees Payroll </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/payroll-export"> Generate Exports </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> <router-link to="/download-exports"> Download Exports </router-link></li>
                <li class="breadcrumb-item active" aria-current="page"> Error Logs </li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-4 col-md-4 col-sm-12 col-form-label text-nowrap"
                        >Generated At</label>
                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <b-input-group size="md" class="mb-1em">
                                <date-pick
                                    v-model="urlBody.generated_at"
                                    :format="'YYYY-MM-DD'"
                                ></date-pick>
                            </b-input-group>
                        </div>
                    </div>
                </b-col>

                <b-col lg="1" class="mb-1rem">
                    <button class="btn btn-primary" @click="onFiltered">
                        Filter
                    </button>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                no-local-sorting
                :items="items"
                :fields="fields"
                @sort-changed="customSorting"
                responsive
            >
                <template v-slot:cell(month)="row">
                    {{ getMonthName(row.item.month) }}
                </template>
                <template v-slot:cell(emp_id)="row">
                    <span v-if="row.item.emp_id">

                    {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                    </span>
                </template>
                <template v-slot:cell(generated_by)="row">
                    <span v-if="row.item.generated_by">{{row.item.generated_by_employee.full_name}} [IES-{{row.item.generated_by}}]</span>
                    <span v-else>System</span>
                </template>
            </b-table>

            <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
export default {
    components: {
        NotFound,
        DatePick
    },
    data() {
        return {
            urlBody: {
                generated_at: '',
                page: 1,
                per_page: 10,
                sort_by: "",
                order: "",
            },
            api_error: "",
            fields: [
                { key: "created_at", label: 'Generated At', class: 'text-nowrap',sortable: true },
                { key: "year" },
                { key: "month" },
                { key: "emp_id",label: 'Employee', class: 'text-nowrap',sortable: true  },
                { key: "log_type" },
                { key: "description" },
                { key: "generated_by", class: 'text-nowrap' }
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:""
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_GET_ERROR_LOGS");
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            api_params.page = api_params.page ?? this.urlBody.page;
            api_params.per_page = api_params.per_page ?? this.urlBody.per_page;
            this.getErrorLogs(api_params);
        },

        getDefaultFilters() {
            let api_params = {
                generated_at: this.urlBody.generated_at,
                page: this.urlBody.page,
                per_page: this.urlBody.per_page
            };
            return api_params;
        },

        setDefaultFilters() {
            const api_params = this.$route.query;
            this.urlBody.generated_at = api_params.generated_at ?
                api_params.generated_at : this.urlBody.generated_at;
        },

        onFiltered() {
            this.getErrorLogs(this.getDefaultFilters());
        },
        
        getErrorLogs: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_error_logs, query).then(response => {
                const result = response.data.data;
                this.items = result.data;
                this.urlBody.page = result.current_page;
                this.lastPage = result.last_page;
                this.urlBody.per_page = result.per_page;
                this.totalRows = result.total;
                this.api_error = "";
                this.updateProgressBar(true);
                
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
                this.setDefaultFilters();
            }).catch(err => {
                console.log(err);
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getErrorLogs(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.api_error = "";
            this.getErrorLogs(newQuery);
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.getErrorLogs(urlBody);
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    padding-right: 0 !important;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 114% !important;
}
.vdpClearInput {
    display: none !important;
}
.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
}
.fa-2x {
    font-size: 1.5em !important;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}

</style>
